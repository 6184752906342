import { apiSlice } from './apiSlice';
import {
  AuthResponse,
  LoginDetails,
  EmailVerificationResponse,
  ResetRequest,
  SignUpDetails,
  GoogleLoginDetails,
  ResetRequestResponse,
  PasswordReset,
  PasswordResetResponse,
  BusinessProfileResponse,
  TwoFAResponse,
  CountriesRequest,
  CountriesResponse,
  TwoFaVerifcationRequest,
  LoginResponse,
  TokenPayload,
  TwoFAOptionResponse,
  UserProfileResponse,
  PasswordChange,
  PasswordReset2FA,
} from './authSliceTypes';

const authSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    //Generate Email Verification OTP
    generateEmailOTP: build.mutation<EmailVerificationResponse, string>({
      query: (email) => ({
        url: `/v4/two-factor-authentication/email/signup-code`,
        method: 'POST',
        body: { email },
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },
      }),
    }),

    //Generate Email Verification OTP For Existing User
    generateEmailOTPForExistingUser: build.mutation<
      EmailVerificationResponse,
      void
    >({
      query: () => ({
        url: `/v4/two-factor-authentication/email/code`,
        method: 'POST',
      }),
    }),

    //Create account for a user
    signUp: build.mutation<AuthResponse, Partial<SignUpDetails>>({
      query: (payload) => ({
        url: '/v4/auth/sign-up',
        method: 'POST',
        body: payload,
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },
      }),
    }),

    //setup 2fa
    twofaSetup: build.mutation<TwoFAResponse, void>({
      query: () => ({
        url: `/v4/two-factor-authentication/authenticator`,
        method: 'POST',
      }),
    }),

    //verify 2fa
    verify2fa: build.mutation<TwoFAResponse, Partial<TwoFaVerifcationRequest>>({
      query: (payload) => ({
        url: `/v4/two-factor-authentication`,
        method: 'PUT',
        body: payload,
      }),
    }),

    //deactivate 2fa
    deactivate2fa: build.mutation<TwoFAResponse, void>({
      query: () => ({
        url: `/v4/two-factor-authentication`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TwoFA'],
    }),

    //2fa Options
    twofaOptions: build.query<TwoFAOptionResponse, void>({
      query: () => ({
        url: `/v4/two-factor-authentication/option`,
      }),
      providesTags: ['TwoFA'],
    }),

    //setup 2fa
    passwordChange: build.mutation<TwoFAResponse, PasswordChange>({
      query: (body) => ({
        url: `/v1/auth/password/change`,
        method: 'POST',
        body,
      }),
    }),

    //Get Supported Countries
    getSupportedCountries: build.query<CountriesResponse, CountriesRequest>({
      query: ({ search, page, pageSize }) =>
        `/v4/countries/?page=${page}&pageSize=${pageSize}&search=${search}`,
    }),

    //Login a user
    logIn: build.mutation<LoginResponse, Partial<LoginDetails>>({
      query: (payload) => ({
        url: '/v4/auth/sign-in',
        method: 'POST',
        body: payload,
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },
      }),
    }),

    //Login a user with 2fa
    logInTwoFa: build.mutation<LoginResponse, Partial<LoginDetails>>({
      query: (payload) => ({
        url: '/v4/auth/sign-in/2fa',
        method: 'POST',
        body: payload,
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },
      }),
    }),

    //Login via Google
    logInWithGoogle: build.mutation<AuthResponse, Partial<GoogleLoginDetails>>({
      query: (payload) => ({
        url: `/v2/auth/${'Google'}/login`,
        method: 'POST',
        body: payload,
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },
      }),
    }),

    //Initialise Password Reset
    initialiseReset: build.mutation<
      ResetRequestResponse,
      Partial<ResetRequest>
    >({
      query: (payload) => ({
        url: `/v4/auth/forgot-password?email=${payload.email}`,
      }),
    }),

    //Reset Password
    resetPassword2fa: build.mutation<
      PasswordResetResponse,
      Partial<PasswordReset2FA>
    >({
      query: (payload) => ({
        url: `/v4/auth/forgot-password/2fa`,
        method: 'POST',
        body: payload,
      }),
    }),

    //Reset Password
    resetPassword: build.mutation<
      PasswordResetResponse,
      Partial<PasswordReset>
    >({
      query: (payload) => ({
        url: `/v2/auth/password/reset`,
        method: 'POST',
        body: payload,
      }),
    }),

    //Get User's profile
    getUserProfile: build.query<UserProfileResponse, string>({
      query: (userId) => `/v2/users/${userId}/profile`,
    }),

    //Get User's business
    getBusinessProfile: build.query<BusinessProfileResponse, void>({
      query: () => `/v2/businesses/profile`,
      providesTags: ['Profile'],
    }),

    //Register a business
    registerBusiness: build.mutation<PasswordResetResponse, Partial<FormData>>({
      query: (payload) => ({
        url: `/v2/businesses`,
        method: 'POST',
        body: payload,
      }),
    }),

    //Delete account
    deleteAccount: build.mutation<PasswordResetResponse, void>({
      query: () => ({
        url: `/v2/auth/deleteAccount`,
        method: 'DELETE',
      }),
    }),

    // Refresh Token
    refreshToken: build.mutation<AuthResponse, TokenPayload>({
      query: (payload) => ({
        url: '/v4/auth/refresh-token',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLogInMutation,
  useLogInWithGoogleMutation,
  useInitialiseResetMutation,
  useGenerateEmailOTPMutation,
  useSignUpMutation,
  useResetPasswordMutation,
  useGetBusinessProfileQuery,
  useRegisterBusinessMutation,
  useDeleteAccountMutation,
  useTwofaSetupMutation,
  useGetSupportedCountriesQuery,
  useVerify2faMutation,
  useLogInTwoFaMutation,
  useRefreshTokenMutation,
  useTwofaOptionsQuery,
  useGetUserProfileQuery,
  useGenerateEmailOTPForExistingUserMutation,
  useDeactivate2faMutation,
  usePasswordChangeMutation,
  useResetPassword2faMutation,
} = authSlice;
